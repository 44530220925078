<template>
  <!-- 数字通证管理 -->
  <div>
    <!-- 新增按钮 -->
    <div class="onBtn">
      <el-button type="success" style="margin-left: auto" @click='addGen' >新增</el-button>
    </div>
    <!-- 内容区域 -->
    <div>
      <el-table :data="form" border style="width: 100%">
        <el-table-column prop="tokenName" label="数字通证名称"  align="center"> </el-table-column>
        <el-table-column prop="tokenNum" label="数字通证编号"  align="center"> </el-table-column>
        <el-table-column prop="price" label="原价区价格"  align="center"> </el-table-column>
        <el-table-column prop="agreementPrice" label="协议区价格"  align="center"> </el-table-column>
        <el-table-column prop="rate" label="扣除差价百分比"  align="center"> </el-table-column>
        <el-table-column prop="rockTime" label="锁仓天数"  align="center"> </el-table-column>
        <el-table-column prop="shopRate" label="商城流入百分比"  align="center"> </el-table-column>
        
        <el-table-column prop="status" 
        label="状态"  align="center">
            <template v-slot="{row}">
                {{row.status==1?'下线':'上线'}}
            </template>
        </el-table-column>
        <el-table-column prop="" 
        label="操作"  align="center">
            <template v-slot="{row}">
                <el-button @click='editInfo(row)' type='text'>编辑</el-button>
                <el-button @click='editInfo2(row)' type='text'>通证配置</el-button>
                <el-button @click='openWhite(row)' type='text'>查看白名单</el-button>
                <el-button @click='openDetail(row)' type='text'>查看通证详情</el-button>
                <!-- <el-button @click='editInfo3(row)' type='text'>通证划拨记录</el-button> -->
                <!-- <el-button @click='editInfo4(row)' type='text'>通证流水记录</el-button> -->
            </template>
        </el-table-column>
      </el-table>
    </div>
      <el-dialog title="白名单" center :visible.sync="white">
          <div style="display: flex; flex-direction: column; align-items: center">
            <el-table :data="whiteList" style="width: 100%">
              <el-table-column prop="id" label="ID" width="180"> </el-table-column>
              <el-table-column prop="tel" label="账户" width="180"> </el-table-column>
              <el-table-column label="操作" align="center">
                <template v-slot="scope">
                  <el-button type="text" @click="deleteWhite(scope.row.id)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-button type="primary" @click="addWhite" style="margin-top: 1.25rem">添加白名单</el-button>
          </div>
        </el-dialog>
        <el-dialog title="通证详情" center :visible.sync="showDetail">
          <div style="display: flex; flex-direction: column; align-items: center">
            <el-table :data="tzDetail" style="width: 100%">
              <el-table-column prop="addressNum" label="归集地址原价持有量" > </el-table-column>
              <el-table-column prop="addressAgreementNum" label="归集地址特价持有量" > </el-table-column>
               <el-table-column prop="allNum" label="原价流通总量" > </el-table-column>
                <el-table-column prop="allAgreementNum" label="特价流通总量" > </el-table-column>
                 <el-table-column prop="userNum" label="原价当前流通量" > </el-table-column>
                  <el-table-column prop="userAgreementNum" label="特价当前流通量" > </el-table-column>
            </el-table>
          </div>
        </el-dialog>
     <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 15, 20, 30]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
   data(){
    return{
        form:[],
        total:0,
        pageSize:10,
        currentPage:1,
        white:false,
        whiteList:[],
        nowId:'',
        tzDetail:[],
        showDetail:false
    }
   },
   mounted(){
    this.getList()
   },
   methods:{
    getList(){
         this.axios
        .get('/admin/gen/findAll', {
          pageNumber: this.currentPage,
          pageSize: this.pageSize
        }).then(res=>{
            this.form=res.data.data.records
            this.total=res.data.data.total
        })
    },
    openWhite(row) {
        this.axios
          .get('/admin/gen/getGenWhite', {
            firmId: row.id
          })
          .then(res => {
            this.nowId = row
            this.white = true
            this.whiteList = res.data.data
          })
    },
    openDetail(row){
       this.axios
          .get('/admin/gen/findById', {
            id: row.id
          })
          .then(res => {
            this.showDetail=true
           this.tzDetail=[res.data.data]
          })
    },
    addWhite(){
      this.$prompt('请输入白名单账户', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(({ value })=>{
        this.axios.get('/admin/gen/addGenWhite',{
            genId:this.nowId.id,
            tel:value
      }).then(res=>{
          if (res.data.code != 200) {
                  this.$message({
                    type: 'error',
                    message: res.data.data
                  })
                } else {
                  this.openWhite(this.nowId)
                  this.$message({
                    type: 'success',
                    message: '添加成功'
                  })
              }
      })
      })
      
    },
    addGen(){
         this.$router.push('editGen')
    },
    editInfo(row){
        this.$router.push({path:'editGen',query:{id:row.id}})
    },
    editInfo2(row){
         this.$router.push({path:'genAllocation',query:{genId:row.id}})
    },
    editInfo3(row){
       this.$router.push({path:'TransferRecord',query:{genId:row.id}})
    },
    editInfo4(row){
      this.$router.push({path:'waterRecord',query:{genId:row.id}})
    },
     // 分页
    handleSizeChange(val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getList()
    },
   }
}
</script>

<style lang="scss" scoped>
.onBtn {
  display: flex;
  align-items: center;
  padding: 20px;
}
.btn {
  display: flex;
  justify-content: center;
  div {
    margin: 0 3px;
    font-size: 12px;
    color: #409eff;
  }
  div:hover {
    cursor: pointer;
  }
}
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
</style>